import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GiftService } from './layout/gift-detail/services/gift-detail.service';
import { ShareModule } from './layout/share/share.module';
import { LocationInterceptor } from 'src/app/location.interceptor';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { CheckVerifyUser } from './public/lib';
import { NzIconModule, NZ_ICONS } from 'ng-zorro-antd/icon';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import {CommonService} from './public/service/common.service';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};

const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => {
  const i = antDesignIcons[key];
  return i;
});
const SERVICE = [CheckVerifyUser]


@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ShareModule,
    NzIconModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    ScreenOrientation,
    GiftService,
    Geolocation,
    CommonService,
    {
      provide: NZ_ICONS,
      useValue: icons
    },
    { provide: HTTP_INTERCEPTORS, useClass: LocationInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ...SERVICE
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
