import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {
    OpenAppComponent,
    VerifyPhoneComponent,
    OtpComponent,
    AuthenticationComponent,
    AuthenticationLoginComponent,
    AuthenticationRegisterComponent,
    ForgotPasswordComponent,
    BuyCardComponent,
    StepFirstComponent,
    ResultComponent
} from './index';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {LoadingComponent} from './loading/loading.component';
import {AlertComponent} from './alert/alert.component';

@NgModule({
    declarations: [
        OpenAppComponent,
        VerifyPhoneComponent,
        OtpComponent,
        AuthenticationComponent,
        AuthenticationLoginComponent,
        AuthenticationRegisterComponent,
        ForgotPasswordComponent,
        BuyCardComponent,
        StepFirstComponent,
        ResultComponent,
        LoadingComponent,
        AlertComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IonicModule,
        FormsModule
    ],
    exports: [
        OpenAppComponent,
        VerifyPhoneComponent,
        OtpComponent,
        AuthenticationComponent,
        AuthenticationLoginComponent,
        AuthenticationRegisterComponent,
        ForgotPasswordComponent,
        BuyCardComponent,
        StepFirstComponent,
        ResultComponent,
        LoadingComponent,
        AlertComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class ShareModule {
}
