import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AlertComponent} from './layout/share/alert/alert.component';

const routes: Routes = [
  { path: '', redirectTo: 'luckywheel', pathMatch: 'full' },
  {
    path: 'luckywheel',
    loadChildren: () => import('./layout/luckywheel/luckywheel.module').then(m => m.LuckyWheelModule)
  },
  {
    path: 'accumulate-point',
    loadChildren: () => import('./layout/accumulate-point/accumulate-point.module').then(m => m.AccumulatePointModule)
  },
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'luckywheel/:target/:type/:phone'
  // }
  {
    path: 'gift-detail',
    loadChildren: () => import('./layout/gift-detail/gift-detail.module').then(m => m.GiftDetailModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
