import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
})
export class ResultComponent implements OnInit {
  @Input() data: any;
  @Input() typeGift: any;
  @Output() eventEmitter = new EventEmitter<any>();
  imageAsset = '../../../../../assets/images';
  @Input() typeCard: any;
  // tslint:disable-next-line:variable-name
  label_phone;
  constructor(
      private modalController: ModalController,
  ) { }

  ngOnInit() {
    // const phone = this.data.phone;
    // const length = phone.length;
    // const c = length % 3;
    // if (c) {
    //   const d = phone.slice(0, c);
    //   const d2 = phone.slice(c, length);
    //   let d3 = '';
    //   for (let i = 0; i < d2.length / 3; i++) {
    //     const p = d2.slice(3 * i, 3 * i + 3);
    //     d3 = d3 + ' ' + p;
    //   }
    //   this.label_phone = d + d3.slice(1, d3.length);
    // } else {
    //   let d = '';
    //   for (let i = 0; i < length / 3; i++) {
    //     const p = phone.slice(3 * i, 3 * i + 3);
    //     d = d + ' ' + p;
    //   }
    //   this.label_phone = d.slice(1, d.length);
    // }
    // this.label_phone = '+84 ' + this.label_phone.substring(1, this.label_phone.length);
  }
  retry() {
    this.eventEmitter.emit({
      step: 1
    });
  }

  closeModal(statusCode: number) {
    this.modalController.dismiss({statusCode});
  }
}
