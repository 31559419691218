import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {
    getStatusVoucher(voucher) {
        // status = false, code = START_TIME_CAN_USE => voucher chưa đến hạn sử dụng
        // status = false, code = MAX_NUM_OF_USED_VOUCHER => voucher quá số lần sử dụng
        // status = false, code = END_TIME_CAN_USE => voucher quá hạn hạn sử dụng
        // status = false, code = EFFECTIVE_TIME => voucher quá hạn sử dụng
        // status = false, code = MAX_NUM_OF_USED_CUSTOMER => quá lượt sử dụng của mỗi khách hàng
        // console.log('voucher', voucher);
        const condition = voucher?.checked_condition;
        if (condition?.status) {
            return 'Có thể sử dụng';
        } else {
            // return 'Hết hạn sử dụng';
            if (condition?.code === 'START_TIME_CAN_USE') {
                return 'Chưa có hiệu lực';
            } else if (condition?.code === 'MAX_NUM_OF_USED_VOUCHER' || condition?.code === 'MAX_NUM_OF_USED_CUSTOMER') {
                return 'Hết lượt sử dụng';
            } else if (condition?.code === 'BUSINESS_LOCKED_VOUCHER' || condition?.code === 'ADMIN_LOCKED_VOUCHER') {
                return 'Đã bị khoá';
            } else {
                return 'Hết hạn sử dụng';
            }
        }
    }
}
