import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {FormBuilder, Validators} from "@angular/forms";

@Component({
  selector: 'app-verify-phone',
  templateUrl: './verify-phone.component.html',
  styleUrls: ['./verify-phone.component.scss'],
})
export class VerifyPhoneComponent implements OnInit {
  form = this.fb.group({
    phone: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(14), Validators.minLength(9)]],

  })
  constructor(
      private  fb: FormBuilder,
      private modalController: ModalController
  ) { }

  ngOnInit() {}
  confirmPhone() {
    // if (this.form.invalid) {
    //   alert('Số điện thoại không hợp lệ!');
    //   return;
    // }
    if (!this.form.controls.phone.value) {
      return;
    }
    let phone = this.form.controls.phone.value.trim();
    if (phone.split('')[0] !== 0 && phone.length === 9 ) {
      phone = `0${phone}`;
    }
    this.modalController.dismiss({phone: phone});
  }
  closeModal() {
    this.modalController.dismiss();
  }
}
